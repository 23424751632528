/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useLocation } from "react-router-dom";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Container,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Link,
  Badge,
  Icon
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate } from "react-router-dom";
import { NavLinksProps, CustomListItemProps } from "@propTypes/index";
import { AppBarStyle } from "customStyles/index";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddchartIcon from '@mui/icons-material/Addchart';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useCustomModalStore, useTableStore, useUserStore } from "../../../state";
import { useRapbackSubscription } from "hooks/useRapbackSubscription";
import { AlertColor } from "../../../customEnums/AlertColor";
import { TableNames } from "customEnums/TableNames";
import { queryParams } from "../rbSubBadgeQuery"
import { Order } from "customTypes/OrderType";
import { urls } from '../../../urls'
import { CustomModal } from './CustomModal'

const CustomListItem: React.FC<CustomListItemProps> = ({ children, expandable, lastTopItem = false, pageName, expanded, label, openMenu, handleToggleMenu, handleDrawerOpen, setOpen, outstandingRapback }) => {
  const navigate = useNavigate();

  //const { dashboardUrl, fetchEmbedUrl, loadingUrl } = useDashboardUrl(userEmail ? userEmail : "")
  const userConfig = useUserStore(state => state.userConfig)
  const { usersPermissions, userGroup, userEmail } = userConfig;

  const setDialogStyles = useCustomModalStore(state => state.setDialogStyles)
  const setOpenCustomModal = useCustomModalStore(state => state.setOpenCustomModal)
  const setCustomModalTitle = useCustomModalStore(state => state.setCustomModalTitle)
  const setContentText = useCustomModalStore(state => state.setContentText)
  const setContentStyle = useCustomModalStore(state => state.setContentStyle)
  const setProgressVariant = useCustomModalStore(state => state.setProgressVariant)
  const setModalActionsConfig = useCustomModalStore(state => state.setModalActionsConfig)
  const handleLink = (page: string) => {
    if (page !== "reports") {
      navigate(`/app/${page}`)
    } else {

      setCustomModalTitle("Redirect Notice")
      
      

      const fetchEmbedUrl = async () => {
        const controller = new AbortController();
        const { signal } = controller;
        const url = urls.TRANSACTION
        let fetchUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/transaction/embededDashboardUrl`

        setContentText("Redirecting to reports dashboard.")
        setDialogStyles({height: "200px"})
        setContentStyle({display: "flex", flexDirection: "column", justifyContent: "space-evenly"})
        setProgressVariant("indeterminate")
        setModalActionsConfig([])
        setOpenCustomModal(true)

        //setLoadingUrl(true)
        try {
           const resp = await fetch(fetchUrl, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    email: userEmail,
                    group: userGroup 
                }),
                signal
            });

            console.log('error resp: ', resp)
            if(resp.status != 200) {
              setContentText("Error retrieving reports.")
              setProgressVariant(undefined)
              setModalActionsConfig([
                {
                  onClick: () => setOpenCustomModal(false),
                  variant: "contained",
                  buttonTitle: "Cancel"
                },
                {
                  onClick: fetchEmbedUrl,
                  variant: "contained",
                  buttonTitle: "Retry"
                }
                
              ])
            } else {
              const { embedUrl } = await resp.json()

              window.open(embedUrl, '_blank')
              setOpenCustomModal(false)


            }

        } catch (err: any) {
            console.log('use dashboard url error: ', err);
        }
      }

      
      fetchEmbedUrl()
    }
  }

  const listItemStyle = {
    alignItems: "flex-end",
    display: "flex",
    ...(lastTopItem === true && { flex: 1 })
  }

  console.log('lastTopItem', lastTopItem)

  return (
    <>
      {(
        //console.log(`debug NavLinks display pageName: ${pageName} lastTopItem: ${lastTopItem} listItemStyle: `, listItemStyle),
        pageName !== "administration" && pageName !== "reports" && pageName !== "rapback-subscriptions"
          ? <ListItem disablePadding sx={{ ...listItemStyle, display: 'block' }}>
            <ListItemButton onClick={() => {
              handleLink(pageName);
              setOpen(false);
            }
            }
              sx={{ ...AppBarStyle.listItemButtonStyle, justifyContent: expanded ? 'initial' : 'center' }}>
              <ListItemIcon sx={{ mr: expanded ? 0 : 'auto', justifyContent: expanded ? "space-between" : "center", }}>
                {children}
              </ListItemIcon>
              <ListItemText primary={label} sx={{ opacity: expanded ? 1 : 0, "&.MuiListItemText-root": { textWrap: "wrap" } }} />
              {
                pageName == "rapback-prenotifications" && outstandingRapback.preNotification &&
                <CircleIcon fontSize="small" sx={{ color: 'error.dark', width: '8px', height: '8px' }} />
              }
              {
                pageName == "rapback-validations" && outstandingRapback.validations &&
                <CircleIcon fontSize="small" sx={{ color: 'error.dark', width: '8px', height: '8px' }} />
              }
            </ListItemButton>
          </ListItem>
          : <ListItem disablePadding sx={{ ...listItemStyle, display: "block" }}>
            <ListItemButton onClick={expandable ? () => {
              handleDrawerOpen?.()
              handleToggleMenu?.()
            } : () => {
              handleLink(pageName)
            }}
              sx={{ ...AppBarStyle.listItemButtonStyle, justifyContent: expanded ? 'initial' : 'center' }}
              //disabled={loadingUrl && pageName == "reports"}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: expanded ? 3 : 'auto', justifyContent: 'center', }}>
                {children}
              </ListItemIcon>
              <ListItemText primary={label} sx={{ opacity: expanded ? 1 : 0, "&.MuiListItemText-root": { textWrap: "wrap" } }} />
              {
                expanded && (
                  (expandable && openMenu
                    ? <ExpandLessIcon onClick={(e) => {
                      e.stopPropagation()
                      handleToggleMenu?.()
                    }} />
                    : !expandable
                      ? <OpenInNewIcon color="action" />
                      : <ExpandMoreIcon onClick={(e) => {
                        e.stopPropagation()
                        handleToggleMenu?.()
                      }} />
                  ))
              }
            </ListItemButton>
          </ListItem>
      )}
    </>
  )
}

const drawerWidth = 256

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: 256,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const NavLinks: React.FC<NavLinksProps> = ({
  handleDrawerOpen, expanded, handleDrawerClose, setOpen }) => {
  const { pathname } = useLocation();
  const [, , pageName] = pathname.split('/');

  const theme = useTheme()
  const [openMenu, setOpenMenu] = useState(false)
  const [openRapbackSubs, setOpenRapbackSubs] = useState(false)

  const outstandingRapback = useTableStore((state) => state.outstandingRapback)
  const searchParams = useTableStore((state) => state.searchParams)
  const userConfig = useUserStore(state => state.userConfig)
  const setSeverity = useTableStore(state => state.setSeverity);
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const needsAttention = useTableStore(state => state.needsAttention);
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)

  const { userRole, usersPermissions, userEmail, userClosedRbSub } = userConfig;

  const navigate = useNavigate();
  const STATE = process.env.REACT_APP_CONFIG_STATE!;
  const enableRBSubscriptions = STATE === "ga"
  const enableRapbackSummary = true;
  const enableBatchPrinting = STATE != "ga"

  useRapbackSubscription(pageName, 25,
    1,
    "desc",
    "insertDate",
    userRole,
    searchParams, true, TableNames.RAPBACK_PRENOTIFICATIONS, queryParams, true)

  useEffect(() => {
    if (needsAttention && !userClosedRbSub) {
      setAlertTitle("Attention Required")
      setAlertMessage("Rapback subscriptions require your attention.")
      setOpenNotification(true)
      setSeverity(AlertColor.WARNING)
    } else {
      setAlertTitle("")
      setAlertMessage("")
      setOpenNotification(false)
      setSeverity("")
    }
  }, [needsAttention])

  const handleLink = (page: string) => {
    navigate(`/app/${page}`)
  }

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  const handleToggleRapbackSubs = () => {
    setOpenRapbackSubs((!openRapbackSubs))
  }

  useEffect(() => {
    if (expanded) {
      setOpenMenu(true)
      setOpenRapbackSubs(true)
    }
    if (!expanded) {
      setOpenMenu(false)
      setOpenRapbackSubs(false)
    }
  }, [expanded])

  //const { dashboardUrl, fetchEmbedUrl, loadingUrl } = useDashboardUrl(userEmail ? userEmail : "")

  const getCount = (obj: object) => {
    console.log('debug rapback badge obj: ', obj)
    return Object.values(obj).filter(value => value === true).length
  }

  console.log('outstanding rapback: ', outstandingRapback);

  return (

    <Drawer variant="permanent" open={expanded}>
      <DrawerHeader>
        <Tooltip title={expanded ? "Close Drawer" : ""}>
          <IconButton onClick={() => {
            setOpenRapbackSubs(false)
            handleDrawerClose()
          }}
            tabIndex={expanded ? 0 : -1}
          >
            <ArrowBackIcon aria-label="close-drawer" />
          </IconButton>
        </Tooltip>

      </DrawerHeader>
      <Divider />
      <List sx={AppBarStyle.listStyle}>
        <CustomListItem pageName="transaction-summary" label="Transaction Summary" expanded={expanded} setOpen={setOpen}>
          <Tooltip title="Transaction Summary">
            <SwapHorizIcon color={pageName === 'transaction-summary' ? "primary" : "inherit"} aria-label="Transaction Summary" />
          </Tooltip>
        </CustomListItem>
        {
          (usersPermissions?.['viewRapBack']) && enableRapbackSummary && (
            <CustomListItem lastTopItem={(usersPermissions?.['viewBatchPrinting'] || usersPermissions?.['viewRapbackSubscriptions']) ? false : true} pageName="rapback-summary" label="Rapback Summary" expanded={expanded} setOpen={setOpen}>
              <Tooltip title="Rapback Summary">
                <LocalPoliceOutlinedIcon color={pageName === 'rapback-summary' ? "primary" : "inherit"} aria-label="Rapback Summary" />
              </Tooltip>
            </CustomListItem>
          )
        }
        {/*Last icon of the top half must set lastTopItem true. If adding new icons remove lastTopItem from previous last item and add to newest item*/}
        {
          enableRBSubscriptions && usersPermissions?.['viewRapbackSubscriptions'] && (
            <CustomListItem
              expandable={true}
              lastTopItem={!enableBatchPrinting && enableRBSubscriptions && !openRapbackSubs}
              handleDrawerOpen={handleDrawerOpen}
              handleToggleMenu={handleToggleRapbackSubs}
              openMenu={openRapbackSubs}
              pageName="rapback-subscriptions"
              label="Rapback Subscriptions"
              expanded={expanded}
              setOpen={setOpen}
            >

              <Badge badgeContent={getCount(outstandingRapback)} sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'error.dark',
                  color: 'white'
                }
              }}>

                <Tooltip title="Rapback Subscriptions">
                  <BookmarkBorderIcon
                    color={pageName === 'rapback-maintenance' || pageName === 'rapback-prenotifications' && !expanded || pageName === 'rapback-validations' && !openRapbackSubs
                      ? "primary" : "inherit"}
                    aria-label="Rapback-Subscriptions"
                  />
                </Tooltip>
              </Badge>
            </CustomListItem>
          )
        }
        {
          openRapbackSubs && expanded && usersPermissions?.['viewRapbackSubscriptions'] && (
            <CustomListItem pageName="rapback-maintenance" label="Maintenance" expanded={expanded} setOpen={setOpen}>
              <Icon></Icon>
            </CustomListItem>
          )
        }
        {
          openRapbackSubs && expanded && usersPermissions?.['viewRapbackSubscriptions'] && (
            <CustomListItem pageName="rapback-prenotifications" label="Pre-notifications" expanded={expanded} setOpen={setOpen} outstandingRapback={outstandingRapback}>
              <Icon></Icon>
            </CustomListItem>
          )
        }
        {
          openRapbackSubs && expanded && usersPermissions?.['viewRapbackSubscriptions'] && (
            <CustomListItem pageName="rapback-validations" label="Validations" expanded={expanded} setOpen={setOpen} lastTopItem={openRapbackSubs} outstandingRapback={outstandingRapback}>
              <Icon></Icon>
            </CustomListItem>
          )
        }
        {
          usersPermissions?.['viewBatchPrinting'] && enableBatchPrinting && (
            <CustomListItem lastTopItem={true} pageName="batch-printing" label="Batch Printing" expanded={expanded} setOpen={setOpen}>
              <Tooltip title="Batch Printing">
                <AutoAwesomeMotionIcon color={pageName === 'batch-printing' ? "primary" : "inherit"} aria-label="Batch Printing" />
              </Tooltip>
            </CustomListItem>
          )
        }
        {/*Start of bottom half icons*/}
        {
          usersPermissions?.['viewReporting'] && (
            <CustomListItem pageName="reports" label="Reports" expanded={expanded} setOpen={setOpen} userEmail={userEmail}>
              {
                <Tooltip title="Reports">
                  <AddchartIcon color="action" aria-label="Reports" />
                </Tooltip>
              }
            </CustomListItem>
          )

        }
        {
          usersPermissions?.['viewAdministration'] && (
            <CustomListItem
              expandable={true}
              handleDrawerOpen={handleDrawerOpen}
              handleToggleMenu={handleToggleMenu}
              openMenu={openMenu}
              pageName="administration"
              label="Administration"
              expanded={expanded}
              setOpen={setOpen}
            >
              <Tooltip title="Admin">
                <SettingsIcon
                  color={pageName === 'agency-management' || pageName === 'user-management' && !expanded || pageName === 'agency-management' || pageName === 'user-management' && !openMenu
                    ? "primary" : "inherit"}
                  aria-label="Administration"
                />
              </Tooltip>
            </CustomListItem>)
        }
        {
          openMenu && expanded && usersPermissions?.['viewAgencies'] && (
            <CustomListItem pageName="agency-management" label="Agency Management" expanded={expanded} setOpen={setOpen}>
              <Icon></Icon>
            </CustomListItem>
          )
        }
        {
          openMenu && expanded && usersPermissions?.['viewUserManagement'] && (
            <CustomListItem pageName="user-management" label="User Management" expanded={expanded} setOpen={setOpen}>
              <Icon></Icon>
            </CustomListItem>
          )
        }
      </List>
    </Drawer>
  );
};

export default NavLinks;
