import {
    Typography,
    Box
} from '@mui/material';

export const fullPolicyLanguageComponenet = () => {
    return (
        <Box style={{
            overflowY: "auto",
            maxHeight: "500px",
            display: "flex",
            justifyContent: "space-evenly",
            flex: "column"
        }}>
            <Typography variant='body2'>
                Welcome to the Civil Adjudication and Response Solution (“CARES”) Portal. If you continue to browse and use this service, it implies that you are acknowledging that you are an authorized user of the product within the State of [insert state] and you are agreeing to comply with the following terms and conditions of use:
                <p></p>
                WARNING: This site potentially provides access to Criminal Justice Information (CJI). Access and dissemination of said information is done under the authority of the appropriate State Identification Bureau and/or the Federal Bureau of Investigation (FBI). Only authorized persons in the performance of their official duties may access, use or disseminate this information for official and lawful criminal justice purposes. Unauthorized access, unauthorized attempted access, or unauthorized use of the system is a violation of state and/or applicable federal law, and may be subject to prosecution. Individuals using this computing system without authority, or in excess of their authority, are subject to having their activities on this system monitored and recorded by system personnel. All http and ftp accesses are logged. In the course of such monitoring, or in the course of system maintenance or trouble shooting, the activities of authorized users also may be monitored. Anyone using this system expressly consents to such monitoring and is advised that if such monitoring reveals possible evidence of criminal activity, further legal action may be taken. Neither IDEMIA Identity & Security USA LLC, nor its employees or affiliates (“IDEMIA”), shall held be liable for any improper or incorrect use of the CARES system or the information described and/or contained therein and assumes no responsibility for anyone's use of the information.
                <p></p>
                Acceptance of Terms
                <br></br>
                The CARES Solution is operated by IDEMIA, the global leader in trusted identities. When you access this site, you acknowledge that you have read and agree to abide by the terms described below. If you do not agree to the terms discussed here, you should exit this site now.
                <p></p>
                Copyright Notice
                <br></br>
                The data and information appearing on this site is copyrighted material owned by IDEMIA or the original creator of the material. Any use of such material requires IDEMIA's prior written consent. Requests for permission should be sent to the Notice Address below.
                <p></p>
                Trademarks
                <br></br>
                IDEMIA logos are trademarks or service marks of IDEMIA. You may not use these or any other IDEMIA trademarks or service marks without the written permission of IDEMIA.
                <p></p>
                Disclaimer of Warranties/Accuracy and Use of Data/Computer Viruses
                <br></br>
                Although the data found using the C.A.R.E.S. system has been produced and processed from sources believed to be reliable, no warranty expressed or implied is made regarding accuracy, adequacy, completeness, legality, reliability or usefulness of any information. This disclaimer applies to both isolated and aggregate uses of the information. All warranties of any kind, express or implied, including but not limited to the IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, freedom from contamination by computer viruses and non-infringement of proprietary rights ARE DISCLAIMED. If the user finds any errors or omissions, we encourage the user to report them to the appropriate state agency or criminal history repository for review and follow up.
                <p></p>
                Limitation of Liability
                <br></br>
                In no event shall IDEMIA be liable for any direct, indirect, incidental, special, exemplary, or consequential damages (including, but not limited to: procurement of substitute goods or services; loss of use, data, or profits; or business interruption) however caused and on any theory of liability, whether in contract, strict liability, tort (including negligence or otherwise), or any other theory arising in any way out of the use of this system, even if advised of the possibility of such damage. This disclaimer of liability applies to any damages or injury, whether based on alleged breach of contract, tortious behavior, negligence or any other cause of action, including but not limited to damages or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, and/or theft, destruction or unauthorized access to, alteration of, or use of any record.
                <p></p>
                Disclaimer of Third Party Information Accuracy and Endorsement
                <br></br>
                IDEMIA and the C.A.R.E.S. Product are distributors of content supplied by third parties, namely criminal systems of record. Any information or content expressed or made available by third parties, including federal, state and local law enforcement agencies, are those of the respective distributor(s) and is not owned or generated by IDEMIA. IDEMIA shall not be responsible or liable for any loss or damage of any sort incurred as the result of such third-party content.
                <p></p>
                Choice of Law
                <br></br>
                These Terms of Use are governed by the laws of the Commonwealth of Virginia (without regard to the choice of law provisions thereof to the extent that they would result in the application of the law of another jurisdiction). Subject to the preceding sentence, the Parties consent to the jurisdiction and venue in the state courts sitting in Fairfax County, Virginia and federal courts sitting in Alexandria, Virginia.
                <p></p>
                Notice.
                <br></br>
                Questions about or notices under these Terms of Use should be directed (by nationally recognized overnight courier service, certified mail (return receipt requested), or personal delivery) to IDEMIA at the address below. Notice is effective when delivered.
                <p></p>
                Idemia Identity & Security USA LLC
                <br></br>
                Attention: General Counsel
                <br></br>
                11951 Freedom Drive
                <br></br>
                Suite 1800
                <br></br>
                Reston, VA 20190
            </Typography>
        </Box >
    )
}