export enum UserGroups {
    MC_ADMIN = "mc_admin",
    MC_STATE_POLICE_ADMIN = "mc_state_police_admin",
    MC_STATE_POLICE_USER = "mc_state_police_user",
    MC_AGENCY_ADMIN = "mc_agency_admin",
    MC_AGENCY_USER = "mc_agency_user",
    MC_EDIT = "mc_edit",
    MC_READONLY = "mc_readonly",
    MC_READONLY_RESTRICTED_VIEW = "mc_readonly_restricted_view",
}

export enum UserGroupLabel {
    MC_ADMIN_LABEL = "Admin Super User",
    MC_STATE_POLICE_ADMIN_LABEL = "State Police Admin",
    MC_STATE_POLICE_USER_LABEL = "State Police User",
    MC_AGENCY_ADMIN_LABEL = "Agency Admin",
    MC_AGENCY_USER_LABEL = "Agency User",
    MC_EDIT_LABEL = "Edit",
    MC_READONLY_LABEL = "Read-Only",
    MC_READONLY_RESTRICTED_VIEW_LABEL = "Read-Only Restricted",
}