/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useMUITheme } from "../../theme/ThemeProvider";
import { useAuth } from "../../hooks";
import {
  Typography,
  IconButton,
  Container,
  Paper,
  Toolbar,
  Stack,
  MenuList,
  MenuItem,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  Divider,
  ListSubheader,
  Tooltip
} from '@mui/material'
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, useLocation } from "react-router-dom";
import { SharedStateContextType } from "@propTypes/index"
import { UserProfileProps, AppBarProps } from "@propTypes/index";
import { AppBarStyle } from 'customStyles/index';
import { useUserStore } from "state";
import FocusTrap from 'focus-trap-react';

const SharedStateContext = createContext<SharedStateContextType | undefined>(undefined)

const SharedStateProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [displayShortcut, setDisplayShortcut] = useState<boolean>(false)
  return (
    <SharedStateContext.Provider value={{ isVisible, setIsVisible, displayShortcut, setDisplayShortcut }}>
      {children}
    </SharedStateContext.Provider>
  )
}

const useSharedState = (): SharedStateContextType => {
  const context = useContext(SharedStateContext)
  if (!context) throw new Error('useSharedState must be used within a sharedstateprovider')
  return context
}

// const TOGGLE_SHORTCUT_VISIBILITY = "TOGGLE_SHORTCUT_VISIBILITY"
const shortCutsStateReducer = (state: any, action: any): any => {
  switch (action.type) {
    case 'TOGGLE_SHORTCUT_VISIBILITY':
      //console.log('TOGGLE_SHORTCUT_VISIBILITY is toggling')
      return {
        // ...state,
        // shortcuts: state.shortcuts.map((shortcut) => 
        //   shortcut.id === action.payload 
        //   ? {...shortcut, isVisibile: !shortcut.isvisible} 
        //   : shortcut
        // )
        ...state,
        shortcuts: state.shortcuts.map((shortcut) => {

          if (shortcut.id === action.payload) {
            return {
              ...shortcut,
              isVisible: !shortcut.isVisible
            }
          }
          return shortcut
        }
        )
      }
    case 'MARK_SHORTCUT_FOR_VISIBILITY_CHANGE':
      return {
        ...state,
        shortcutsToSave: state.shortcutsToSave.includes(action.payload)
          ? state.shortcutsToSave.filter((id) => id !== action.payload)
          : [...state.shortcutsToSave, action.payload]
      }
    // return {
    //   ...state,
    //   shortcutsToSave: [...state.shortcutsToSave, action.payload]
    // }
    default:
      return state
  }
}

const Shortcut: React.FC<any> = ({ children, label, description, isEditMode, toggleVisibility, shortcutId, dispatch, state }) => {
  const [isVisible, setIsVisible] = useState(state.shortcuts.find(shortcut => shortcut.id === shortcutId)?.isVisible || false);

  const handleVisibleClick = () => {
    setIsVisible(!isVisible)
    toggleVisibility(shortcutId)
    //console.log('toggle visibile')
  }
  const backgroundColor = isVisible ? 'var(--action-disabled-background, rgba(16, 17, 28, 0.12));' : null;
  const showShortCut = state.shortcuts.isVisible ? 'none' : 'flex'

  return (
    <Container maxWidth={false} disableGutters sx={{
      ...AppBarStyle.shortcutContainer,
      backgroundColor: backgroundColor,
      display: showShortCut
    }}>
      <Container sx={{ display: "flex", justifyContent: "flex-end" }} maxWidth={false} disableGutters>
        {isEditMode === true ?
          <Tooltip title={isVisible ? "Hide" : "Show"}>
            <IconButton sx={AppBarStyle.iconButton} onClick={handleVisibleClick}>
              {isVisible ?
                <VisibilityOffIcon />
                :
                <VisibilityIcon aria-label="visibility" />
              }
            </IconButton>
          </Tooltip>
          :
          <IconButton sx={AppBarStyle.iconButton} disabled={true}>
          </IconButton>
        }

      </Container>
      <Container disableGutters sx={AppBarStyle.typographyContainer}>
        {children}
        <Typography color="black" sx={AppBarStyle.shortcutTypography}>
          {label}
        </Typography>
        <Typography color="grey" sx={AppBarStyle.shortcutTypography}>
          {description}
        </Typography>
      </Container>
    </Container>
  )
}

const Shortcuts: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [state, dispatch] = useReducer(shortCutsStateReducer, {
    isEditMode: false,
    shortcuts: [
      {
        id: 1,
        isVisible: true,
        name: 'Dashboard',
        description: 'All Tasks',
        icon: <HomeIcon color="primary" />,
        link: 'transaction-summary'
      },
      {
        id: 2,
        isVisible: true,
        name: 'Transaction Summary',
        description: 'View Transactions',
        icon: <SwapHorizIcon color="primary" />,
        link: 'transaction-summary'
      },
      {
        id: 3,
        isVisible: true,
        name: 'Rapback Summary',
        description: 'View or Print Results',
        icon: <AccessTimeIcon color="primary" />,
        link: 'rapback-summary'
      },
      {
        id: 4,
        isVisible: true,
        name: 'Agency User Management',
        description: 'Settings',
        icon: <StarBorderIcon color="primary" />,
        link: 'agency-management'
      },
    ],
    shortcutsToSave: []
  })

  const toggleEdit = () => {
    setIsEditMode(!isEditMode)
  }

  const handleToggleShortCutVisbility = (shortcutId) => {
    dispatch({ type: 'MARK_SHORTCUT_FOR_VISIBILITY_CHANGE', payload: shortcutId })
  }

  const handleSaveChanges = () => {
    setIsEditMode(!isEditMode)
    state.shortcutsToSave.forEach((shortcutId) => {
      dispatch({ type: 'TOGGLE_SHORTCUT_VISIBILITY', payload: shortcutId })
    })
  }

  useEffect(() => {
    //console.log('debugging shortcuts', state)
  }, [handleToggleShortCutVisbility, handleSaveChanges])

  const handleReset = () => {

  }

  return (
    <>
      <Container disableGutters sx={{ alignItems: "center", marginTop: "5px", paddingTop: "6px", paddingBottom: "6px", paddingLeft: "16px", paddingRight: "16px", display: "flex", flexDirection: "row" }}>
        <Container disableGutters>
          <Typography sx={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "35px",
            letterSpacing: "0.15px",

          }}>Shortcuts</Typography>
        </Container>
        {isEditMode === false ?
          <Tooltip title="Edit">
            <IconButton color="primary" onClick={toggleEdit}>
              < EditIcon />
            </IconButton>
          </Tooltip>

          :
          <Button onClick={handleSaveChanges} size="small" sx={{
            minWidth: "131px",
            maxHeight: "30px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "4px",
            paddingRight: "4px"

          }} aria-label="Save Changes" color="primary" variant="contained">
            Save Changes
          </Button>
        }

      </Container>

      <Container disableGutters sx={{
        display: "flex", flexDirection: "row", flexWrap: "wrap", height: "584px", width: "100%", padding: 1, gap: 1
      }}>
        {
          state.shortcuts.map((shortcut) => (
            <Shortcut
              key={shortcut.id}
              shortcut={shortcut}
              label={shortcut.name}
              description={shortcut.description}
              isVisible={shortcut.isVisible}
              dispatch={dispatch}
              isEditMode={isEditMode}
              toggleVisibility={() => handleToggleShortCutVisbility(shortcut.id)}
              state={state}
            >
              <Link to={shortcut.link}>
                {shortcut.icon}
              </Link>
            </Shortcut>
          ))
        }
        <Button onClick={handleReset}>RESET CHANGES</Button>
      </Container>

    </>
  )
}

const UserProfile: React.FC<UserProfileProps> = ({ userRole, handleLogout, handleOpenDisplaySettings, closeUserSettings, userGivenName, userFamilyName, userInitials }) => {

  //console.log(`userGivenName: ${userGivenName} userFamilyName: ${userFamilyName}`)
  //console.log(`userInitials: ${userInitials}`)


  let formatUserRole = userRole?.split("_");
  const handleKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      closeUserSettings()
    } 
  };

  return (
    <MenuList sx={{ zIndex: 3 }} onKeyDown={handleKeyDown}>
      <MenuItem sx={{ padding: "0px", pointerEvents: 'none' }}>
        <Container disableGutters sx={AppBarStyle.userProfileName}>
          <Avatar sx={{ backgroundColor: '#64646B' }} alt="Avatar" variant="circular" >
            <Typography variant='body1' color='#FFF'>{userInitials}</Typography>
          </Avatar>
          <Container maxWidth={false} disableGutters sx={AppBarStyle.userProfileRole}>
            <Typography variant="body1" color='text.primary'>
              {`${userGivenName} ${userFamilyName}`}
            </Typography>
            {formatUserRole?.length > 3 ?
              <div>
                <Typography variant="body2" color='text.secondary' sx={{ textDecoration: 'wrap' }}>
                  {`${formatUserRole[0]}_${formatUserRole[1]}_`}
                </Typography>
                <Typography variant="body2" color='text.secondary' sx={{ textDecoration: 'wrap' }}>
                  {`${formatUserRole[2]}_${formatUserRole[3]}`}
                </Typography>
              </div>
              :
              <Typography variant="body2" color='text.secondary' sx={{ wordWrap: 'break-word', wordBreak: "break-word", overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                {userRole}
              </Typography>
            }
          </Container>
        </Container>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleOpenDisplaySettings()}>
        <Typography variant="body1" color='text.primary'>Display Settings</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleLogout()} sx={{ gap: '0.5rem' }}>
        <LogoutIcon sx={{ color: 'text.secondary' }} fontSize='small' />
        <Typography variant="body1" color='text.primary'>Logout</Typography>
      </MenuItem>
    </MenuList>
  )
}

const drawerWidth = 256;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Appbar: React.FC<AppBarProps> = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const [openBackDrop, setOpenBackDrop] = useState(false)
  const [shortCuts, setOpenShortCuts] = useState(false)
  const [userProfile, setOpenUserProfile] = useState(false)
  const [displaySettings, setOpenDisplaySettings] = useState(false)
  const [menuWidth, setMenuWidth] = useState("0px")
  const [menuHeight, setMenuHeight] = useState("0px")
  const { mode, toggleTheme } = useMUITheme();
  console.log(`debug group call running useUserCognito from AppBar`)

  const { logout } = useAuth();
  const userConfig = useUserStore(state => state.userConfig)
  const { userGivenName, userFamilyName, userRole } = userConfig;
  const [userInitials, setUserInitials] = useState("")

  const location = useLocation();
  const applicantDetails = location.pathname.includes('applicant-details')
  //console.log('applicantDetails pathname', applicantDetails)

  const profileRef = useRef<HTMLDivElement>(null)
  // const shortcutsRef = useRef<HTMLButtonElement>(null)

  const [isFocused, setIsFocused] = useState(false)

  //console.log('user userGivenName, userFamilyName ', userGivenName, userFamilyName)

  useEffect(() => {
    //console.log(`toolbar click shortCuts ${shortCuts} userProfile ${userProfile}`)
    if (shortCuts) {
      setMenuWidth("384px")
      setMenuHeight("413px")
    }

    if (userProfile) {
      setMenuWidth("220px")
      setMenuHeight("auto")
    }

  }, [shortCuts, userProfile])

  useEffect(() => {
    if (userGivenName?.length > 0 && userFamilyName?.length > 0) {
      setUserInitials(userGivenName?.[0].toUpperCase() + userFamilyName?.[0].toUpperCase())
    } else if (userGivenName?.length > 0 && userFamilyName?.length < 1) {
      if (userGivenName?.length > 1) {
        setUserInitials(userGivenName?.[0].toUpperCase() + userGivenName?.[1].toUpperCase())
      } else {
        setUserInitials(userGivenName?.toUpperCase())
      }
    }
  }, [userGivenName, userFamilyName])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setOpenUserProfile(false)
        //console.log('userprofile closed')
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (shortcutsRef.current && !shortcutsRef.current.contains(event.target as Node)){
  //       setOpenShortCuts(false)
  //       //console.log('shortcuts closed')
  //     } 
  //   }
  //   document.addEventListener('click', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside)
  //   }
  // }, [])

  const handleLogout = () => {
    if (logout) logout();
  };

  const openShortCuts = () => {
    setOpenShortCuts(!shortCuts)
    setOpenUserProfile(false)
  }

  const openUserProfile = () => {
    setOpenUserProfile(!userProfile)
    setOpenShortCuts(false)
  }

  const closeUserSettings = () => {
    setOpenUserProfile(false)
  }

  const handleThemeChange = (e: any) => {
    //console.log('theme: ', e.target.value)
  }

  const handleBackDropClose = () => {
    setOpenBackDrop(false)
  }

  const handleOpenDisplaySettings = () => {
    setOpenBackDrop(true)
    setOpenDisplaySettings(true)
  }

  const handleCloseDisplaySettings = () => {
    setOpenBackDrop(false)
    setOpenDisplaySettings(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCloseDisplaySettings()
      openUserProfile()
    } else if (event.key === 'Escape') {
      closeUserSettings()
    }
    //console.log('Event key: ', event.key)
  };

  return (
    <SharedStateProvider>

      <>
        <AppBar position="fixed" enableColorOnDark color='secondary' open={open}>
          <Toolbar disableGutters sx={{ width: "100%", justifyContent: "space-around", gap: "16px" }}>
            <Container maxWidth={false} disableGutters sx={AppBarStyle.navInnerContainerStyle}>
              {!applicantDetails
                ?
                <Tooltip title={open ? "Close Drawer" : "Open Drawer"}>
                  <IconButton onClick={open ? () => handleDrawerClose?.() : () => handleDrawerOpen?.()} color="inherit" sx={{ ...AppBarStyle.iconContainerStyle, marginRight: "32px" }}>
                    <MenuIcon fontSize="inherit" aria-label="Menu" />   
                  </IconButton>
                </Tooltip>
                :
                null
              }
              <Typography sx={{ ...AppBarStyle.titleStyle, pl: applicantDetails ? '0.8rem' : '0.3rem' }}>
                CARES
              </Typography>
            </Container>
            <Stack spacing="1" direction="row" sx={{ pr: '16px', gap: 1, height: "40px" }}>
              <Tooltip title="Open Help">
                <IconButton color="inherit" sx={AppBarStyle.iconContainerStyle}>
                  <a target="_blank" rel="noopener noreferrer" tabIndex={-1} href="../../help.pdf" style={{ display: 'flex', justifyContent: 'center' }}>
                    <HelpOutlineIcon fontSize="inherit"  sx={{ color: '#fff' }} />
                  </a>
                </IconButton>
              </Tooltip>
              {/* <IconButton color="inherit" onClick={() => openShortCuts()} sx={iconContainerStyle}>
                <GridViewIcon fontSize="inherit" aria-label="Menu" />
              </IconButton> */}
              <Tooltip title="Open User Settings">
                <Avatar onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} tabIndex={0} onClick={() => openUserProfile()} onKeyDown={handleKeyDown}
                  alt="Avatar" variant="circular" ref={profileRef}sx={{ border:isFocused ? '2px solid #005fcc' : 'none', backgroundColor: '#64646B', '&:hover': { cursor: 'pointer' } }}>
                  <Typography variant="body1" color='#fff'>{userInitials}</Typography>
                </Avatar>
              </Tooltip>
            </Stack>
          </Toolbar>
        </AppBar>
        <Paper sx={{ 
          ...AppBarStyle.appBarMenuStyle,
          display: shortCuts || userProfile ? "flex" : "none",
          width: menuWidth,
          height: menuHeight
        }}>

          {
            shortCuts ?
              <Shortcuts /> :
              userProfile ?
                <UserProfile
                  handleOpenDisplaySettings={handleOpenDisplaySettings}
                  handleLogout={handleLogout}
                  closeUserSettings={closeUserSettings}
                  userRole={userRole}
                  userGivenName={userGivenName}
                  userFamilyName={userFamilyName}
                  userInitials={userInitials}
                /> : null
          }

        </Paper>



        {
          displaySettings && openBackDrop

            ? <>
              <Backdrop open={openBackDrop} onClick={handleBackDropClose} sx={AppBarStyle.backDropStyle} />
              <FocusTrap>
                <Container disableGutters sx={AppBarStyle.backDropContainer}>
                  <Container maxWidth={false} disableGutters sx={AppBarStyle.backdropButtonContainer}>
                    <Button
                      onKeyDown={handleKeyDown}
                      onClick={handleCloseDisplaySettings}
                      tabIndex={0}
                      variant="text" size='large' startIcon={<CloseIcon sx={AppBarStyle.closeIcon} />} >
                      <Typography sx={AppBarStyle.closeButtonTextStyle} color='text.primary'>
                        Close
                      </Typography>
                    </Button>
                  </Container>
                  <FormControl sx={{ marginTop: "1rem" }}>
                    <FormLabel sx={AppBarStyle.formLabelStyle} id="theme-radio-buttons-group-label" >
                      <Typography variant="h5" color='text.primary'>
                        Display Settings
                      </Typography>
                    </FormLabel>
                    <Divider sx={{ marginBottom: "15px" }} />
                    <ListSubheader sx={AppBarStyle.listSubHeaderStyle}>
                      COLOR SCHEME
                    </ListSubheader>
                    <RadioGroup
                      aria-labelledby="theme-radio-buttons-group-label"
                      defaultValue={mode}
                      name="radio-buttons-group"
                      sx={{ fontSize: '1rem', ml: '0.4rem' }}
                    >
                      <FormControlLabel value="dark" control={<Radio onChange={toggleTheme} />} label="Dark Mode" />
                      <FormControlLabel value="light" control={<Radio onChange={toggleTheme} />} label="Light Mode" />
                    </RadioGroup>
                  </FormControl>
                </Container>
              </FocusTrap>
            </>
            : null
        }
      </>
    </SharedStateProvider>
  );
};
