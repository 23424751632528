/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { formatDate } from "utils/formatDate";
import { NistParsing } from "interfaces/config"
import dayjs from "dayjs";
import { getCurrentEnv } from "./getCurrentEnv";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const STATE = getCurrentEnv

const fieldValue = (field: string | number | undefined) => field ?? "N/A";

const displayHeightInFeet = (string: string | undefined) => {
  console.log("height string: ", string);
  let height = string ? `${string[0]}'${string.slice(1)}"` : "";
  console.log("height test: ", height);
  return height;
};

export type UserProfileInfo = ReturnType<typeof generateUserProfileInfo>;
export type PersonalInfo = ReturnType<typeof generatePersonalInformation>;
export type TransactionalInfo = ReturnType<typeof generateTransactionalInformation>;
export type RequestingAgencyInfo = ReturnType<typeof generateRequestingAgencyInfo>;
export type StatusHistories = ReturnType<typeof generateStatusHistories>;
export type EmploymentInfo = ReturnType<typeof generateEmploymentInformation>;

export const generateUserProfileInfo = (applicantData: any, nistParsing: NistParsing) => {
  console.log('debug applicantData generateUserProfileInfo: ', applicantData)
  console.log('debug applicantData type 2: ', applicantData.descriptors.data.type2)

  console.log('generateUserProfileInfo debug applicantData generateUserProfileInfo config nistParsing: ', nistParsing)
  const type2 = applicantData.descriptors.data.type2
  console.log('generateUserProfileInfo type2 data: ', type2)
  const nameArray = generateDataName(nistParsing, type2)
  console.log('generateUserProfileInfo name arrary: ', nameArray)
  const { field9 } = applicantData.descriptors.data.type1;
  const { field16, field20, field22, field24, field25, field27, field29, field31, field32, field757, field118, field73, field990 } = applicantData.descriptors.data.type2;

  let fullName = `${capitalizeFirstLetter(nameArray[0])} ${capitalizeFirstLetter(nameArray[1])} ${capitalizeFirstLetter(nameArray[2])} ${handleSuffixMapping(nistParsing, nameArray[3])}`;

  console.log('debug applicantData generateUserProfileInfo config fullName: ', fullName)
  console.log('debug applicantData generateUserProfileInfo header birthdate: ', formatDate(fieldValue(field22) as string))
  
  return {
    name: fieldValue(fullName),
    suffix: nameArray[3],
    status: fieldValue(applicantData.attributes.finalDecision || applicantData.attributes.transactionStatus),
    agency: fieldValue(field73 || applicantData.attributes['ORIG-AGENCY']),
    secondaryOri: fieldValue(STATE === "ga" ? field990 ? field990 : "N/A" : (field757 || applicantData.attributes['ORIG-AGENCY']) ? (field757 || applicantData.attributes['ORIG-AGENCY']) : "N/A"),
    tcn: fieldValue(field9),
    ssn:  fieldValue(field16),
    birthDate: formatDate(fieldValue(field22) as string),
    placeOfBirth: fieldValue(field20),
    race: fieldValue(field25),
    gender: fieldValue(field24),
    height: displayHeightInFeet(fieldValue(field27) as string),
    weight: ` ${fieldValue(field29)} lbs`,
    hairColor: fieldValue(field32),
    eyeColor: fieldValue(field31)
  }
}

export const generateRequestingAgencyInfo = (data: any) => {
  const { field900, field901, field902, field903, field904, field905, field990 } = data.descriptors.data.type2;
  return {
    requestingAgencyOri: fieldValue(field990),
    requestingAgencyName: fieldValue(field900),
    phoneNumber: "N/A",
    emailAddress: fieldValue(field903),
    address: fieldValue(field905),
    city: fieldValue(field901),
    state: fieldValue(field902),
    zipCode: fieldValue(field904)
  }
}

const splitAddress = (fullAddress: string) => {
  //String structure of the address: {employerName},{address1},{address2},{city},{stateOrProvince},{postalCode},{country}

  const parts = fullAddress?.split(",").map(part => part.trim());

  const employerName = parts[0] || "N/A";
  const address1 = parts[1] || "N/A";
  const address2 = parts[2] || "";
  const city = parts[3] || "N/A";
  const stateOrProvince = parts[4] || "N/A";
  const postalCode = parts[5] || "N/A";
  const country = parts[6] || "N/A";

  return {
    employerName: employerName ? fieldValue(employerName) : "N/A",
    address: `${address1} ${address2}`,
    city,
    stateOrProvince,
    postalCode,
    country
  };
};

export const generateEmploymentInformation = (applicantData: any) => {
  const { field39, field40 } = applicantData.descriptors.data.type2;
  console.log('Employer Address: ', field39, "Occupation: ", field40);

  const { employerName, address, city, stateOrProvince, postalCode, country } = splitAddress(field39 ?? "")

  return {
    occupation: fieldValue(field40),
    employerName,
    employerAddress: address,
    employerCity: city,
    employerState: stateOrProvince,
    employerZipCode: postalCode,
    employerCountry: country
  }
}

const modifyAddress = (address: string) => {
   let modifiedAddress = address.replace(/,/g, ', ');

   modifiedAddress = modifiedAddress.trim().replace(/,\s*$/, '');
 
   return modifiedAddress;
};

export const generatePersonalInformation = (applicantData: any, nistParsing: NistParsing) => {
  console.log(`personalInfo ApplicantDetails generatePersonalInformation nistParsing: `, nistParsing)
  console.log(`personalInfo generatePersonalInformation applicantData ${JSON.stringify(applicantData, null, "\t")}`)
  const type2 = applicantData.descriptors.data.type2
  const nameArray = generateDataName(nistParsing, type2)
  let addressString;

  if (process.env.REACT_APP_CONFIG_STAGE === "me") {
    addressString = generateAddress(nistParsing, type2)
  }
  console.log('addressString: ', addressString)
  console.log('generateNameData generatePersonalInformation nameArray: ', nameArray)

  const { field16, field20, field21, field22, field24, field25, field27, field29, field31, field32, field41, field906 } = applicantData.descriptors.data.type2;

  return {
    firstName: nameArray[0], 
    middleName: nameArray[1] ? nameArray[1] : "N/A",
    lastName: nameArray[2],
    suffix: nameArray[3] ? nameArray[3] : "N/A",
    residenceAddress: addressString || modifyAddress(field41 ? field41 : "N/A"),
    mailingAddress: modifyAddress(field906 ? field906 : "N/A"),
    ssn: fieldValue(field16),
    birthDate: formatDate(fieldValue(field22) as string),
    placeOfBirth: fieldValue(field20),
    countryOfCitizenship: fieldValue(field21),
    race: fieldValue(field25),
    gender: fieldValue(field24),
    height: displayHeightInFeet(fieldValue(field27) as string),
    weight: fieldValue(field29),
    hairColor: fieldValue(field32),
    eyeColor: fieldValue(field31)
  };
};

export const generateAggregatedResponses = (applicantData: any) => {
  return applicantData.descriptors.data.type2.field75;
};

const getPdfFileName = (data: any) => {
  return data
  .map(attachment => attachment.attributes?.fileName)
  .filter(Boolean);
}

export const generateTransactionalInformation = (applicantData: any) => {
  console.log('transactionalInfo applicantData: ', applicantData)

  const fileName = getPdfFileName(applicantData.attachments); 
  console.log('debug filename: ', fileName);

  const { field4, field8, field9, field10 } =
    applicantData.descriptors.data.type1;
  const { field757, field999, field998, field73, field990, field996, field119, field37 } = applicantData.descriptors.data.type2;

  console.log(
    "debug applicantData applicantData.datelastmodified ?? '' :",
    formatDate(applicantData.datelastmodified) ?? ""
  ); 

  return {
    ORI: STATE === "ga" ? field73 : field8,
    secondaryOri: STATE === "ga" ? fieldValue(field990) : STATE  === "nm" ? 'N/A' : (field757 ? fieldValue(field757) : fieldValue(field8)),
    UEID: fieldValue(process.env.REACT_APP_CONFIG_STATE === "ga" ? field998 : field999),
    incomingTCN: fieldValue(field10), //this is tcr check
    transactionNumber: fieldValue(field9),
    transactionType: fieldValue(field4),
    assignedAgent: fieldValue(""),
    lastModifiedBy: formatDate(applicantData.datelastmodified) ?? "N/A",
    statusTimeout: fieldValue(""),
    transactionTimeout: fieldValue(""),
    reasonFingerprinted: fieldValue(process.env.REACT_APP_CONFIG_STATE === "ga" ? field996 : field37),
    agency: fieldValue(field119 !== '' ? field119 : "N/A"),
    fileName: fileName[0], 
    agencyIdentifier: "N/A",
    currentStatus: fieldValue(
      applicantData.attributes.finalDecision ||
      applicantData.attributes.transactionStatus
    ), 
  };
};

export const generateStatusHistories = (applicantData: any, index: any) => {
  console.log(
    "debug data applicantData in generateStatusHistories: ",
    applicantData
  );
  return {
    key: index,
    id: applicantData.uuid,
    status: applicantData.status,
    statusDate: applicantData.insert_date
      ? dayjs(applicantData.insert_date).format("MM/DD/YYYY hh:mm:ss A")
      : "",
    agency: applicantData.agency,
  };
};

export const parseName = (name: string) => {
  console.log(`parseName name: ${name}`)
  let arr = name.split(",")
  console.log(`parseName arr: `, arr)
  let firstName, middleName
  let lastName = arr[0]
  let firstMiddle = arr[1].trim().split(" ")

  firstName = firstMiddle[0]
  middleName = firstMiddle[1]
  return [firstName, middleName, lastName]
}

const handleSuffixMapping = (nistParsing: NistParsing, suffix: string) => {
  const suffixMapping = nistParsing.suffix['suffixMapping'];
  suffixMapping.map((obj: { key: string; value: string; }) => {
    if (obj.key === suffix) {
      suffix = obj.value
      return;
    }
   });
   return suffix ?? ''
};

export const generateDataName = (nistParsing: NistParsing, type2: any) => {
  console.log('generateNameData type2: ', JSON.stringify(type2, null, 2))
  const nameFields = ["firstName", "middleName", "lastName", "suffix"]
  let STATE: string = process.env.REACT_APP_CONFIG_STATE!

  const nameArray = nameFields?.map((nameField: string) => {
    let nameKey = nistParsing[nameField]?.key
    let nameValue = nistParsing[nameField]?.value
    console.log(`generateUserProfileInfo generateNameData type2 ${JSON.stringify(type2, null, 2)} name key ${nameKey} nameValue ${nameValue}`)

    let name;
    if (STATE !== "ga") {
      name = type2[nameKey][0][nameValue]
    } else {
      name = type2[nameKey]
    }
    console.log('generateUserProfileInfo generateNameData type2 type 2 name generation: ', name)
    return name
  })
  console.log('generateNameData nameArray: ', nameArray);

  return nameArray

  //   let fullName = type2[nistParsing.fullName.key]
  //   console.log('generateNameData fullName: ', fullName)
  //   let parsedFullName = parseName(fullName)
  //   return parsedFullName
}

const generateAddress = (nistParsing: NistParsing, type2: any) => {
  const addressFields = ["street", "city", "state", "zipCode"]

  const addressArray = addressFields?.map((addressField: string) => {
    let addressKey = nistParsing[addressField].key
    let addressValue = nistParsing[addressField].value
    let address = type2[addressKey][0][addressValue]?.trim()
    return address
  })

  return `${addressArray[0] + ", " + addressArray[1] + ", " + addressArray[2] + ", " + addressArray[3]}`
}
