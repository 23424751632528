/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import {
    generateEmploymentInformation,
    generatePersonalInformation,
    generateRequestingAgencyInfo,
    generateTransactionalInformation,
    generateUserProfileInfo,
} from 'utils/generatePersonData';
import { urls } from '../urls'
import { ApplicantInfo, ApplicantFetchStatus } from '@hookTypes/index';
import { useUserStore } from 'state';
import { useAuth } from './useAuth';
import { handleUnauthorized } from 'utils/handleUnauthorized';

const initialApplicantInfo: ApplicantInfo = { 
    userProfile: {}, 
    personalInfo: {}, 
    transactionalInfo: {}, 
    requestingAgencyInfo: {}, 
    employmentInfo: {} 
};

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useApplicantDetails = (registrationId: string) => {
    const [applicantFetchStatus, setApplicantFetchStatus] = useState<ApplicantFetchStatus>('idle');
    const [applicantInfo, setApplicantInfo] = useState<ApplicantInfo>(initialApplicantInfo);
    const [applicantImageUrl, setApplicantImageUrl] = useState<any>();
    const [ loading, setLoading] = useState<boolean>(false);
    const { logout } = useAuth();
    const config = useUserStore(state => state.config)

    console.log('debug applicant details config: ', config)
    const personUrl = urls.PERSON;
    const transactionUrl = urls.TRANSACTION;
    const STATE = process.env.REACT_APP_CONFIG_STATE!
    console.log('useApplicantDetails config STATE: ', STATE)
    const controller = new AbortController();
    console.log('debug config: ', config)
    
    useEffect(() => {
        setLoading(true);
        if (config.hasOwnProperty("nistParsing")) {
            console.log('useApplicantDetails got config: ', config)

            const { nistParsing } = config
            console.log('debug nistparsing', nistParsing)

            setApplicantFetchStatus('loading');
            const { signal } = controller;
            const fetchApplicantData = async () => {
                try {
                    const applicantData = await (await fetch(`${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${registrationId}`, {
                        method: 'GET',
                        credentials: "include",
                        signal
                    })).json();

                    console.log('update decision applicantData: ', applicantData)

                    handleUnauthorized(applicantData.message, logout);
                    
                    const formattedPersonInfo = {
                        userProfile: generateUserProfileInfo(applicantData, nistParsing),
                        personalInfo: generatePersonalInformation(applicantData, nistParsing),
                        transactionalInfo: generateTransactionalInformation(applicantData),
                        requestingAgencyInfo: generateRequestingAgencyInfo(applicantData),
                        employmentInfo: generateEmploymentInformation(applicantData)
                    };
                    setApplicantInfo(formattedPersonInfo);
                    console.log('ApplicantDetails useApplicantDetaisl formattedPersonInfo: ', formattedPersonInfo)

                    const portraitData = await (await fetch(`${process.env.NODE_ENV === 'production' ? transactionUrl : ""}/portrait/${registrationId}`, {
                        method: 'GET',
                        credentials: "include",
                        signal
                    })).json();
                    const portraitDataUrl = portraitData.urls ?? [];
                    setApplicantImageUrl(portraitDataUrl)
                    setApplicantFetchStatus('success');
                    setLoading(false);
                } catch (err) {
                    console.error(err);
                    setLoading(false);
                    setApplicantFetchStatus('error');
                }
            }
            fetchApplicantData();
        } else {
            console.log('config missing expected key: nistParsing')
        }


        return () => controller.abort()

    }, [config, registrationId, personUrl, transactionUrl]);

    return { applicantInfo, applicantImageUrl, applicantFetchStatus, loading };
}
