/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ExtendModal, AdvancedSearch, RejectCodeTable } from './components'
import { CustomTable } from 'common/CustomTable/CustomTable';
import { Paper, Typography, IconButton, AlertTitle, Tooltip, Table } from '@mui/material'
import { CustomTableTitleAndAlert, RightDrawer, useLocalStorage } from '../../common'
import { useExpanded } from 'components/layouts/ProtectedLayout';
import { useQueryRapBack, useSetConfig } from "hooks";
import { useQueryTransactions } from "hooks";
import Alert from '@mui/material/Alert';
import { useConfigService } from 'hooks/useConfigService';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";
import { SavedSearchActionTypes } from '../../customEnums/TransactionSummaryEnums';
import { Order } from 'customTypes/index';
import { AlertStyles, pageStyles } from 'customStyles/common/index';
import { getCurrentEnv } from 'utils/getCurrentEnv'
import { ColumnState, useTableStore } from 'state/TableStore';
import { GetMaintenanceSchedule } from 'utils/getMaintenanceSchedule';
import { Auth } from 'aws-amplify';
import { useAudit } from "hooks/useAudit";
import { searchReducer } from "./helpers/searchReducer";
import { AlertColor } from '../../customEnums/AlertColor';
import { extractPageName } from '../../common/CustomTable/helpers';
import { savedSearchesReducer } from "./helpers/savedSearchesReducer";
import { LogConfig, logger } from "../../utils";
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';

export const TransactionSummary: React.FC = () => {
  const STATE = getCurrentEnv
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const configService = useTableStore(state => state.configService)
  const setConfigService = useTableStore(state => state.setConfigService)
  const searchParams = useTableStore((state) => state.searchParams)
  const setSearchParams = useTableStore((state) => state.setSearchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
  const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)

  const { alertData, loadingConfig } = configService

  const { addAuditEvent } = useAudit();
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('transactionDate');
  const userConfig = useUserStore(state => state.userConfig)
  const { userRole, usersPermissions, userGroup } = userConfig;
  const config = useUserStore(state => state.config)
  // const [recordId, setRecordId] = useState(null)
  const [extendMessage, setExtendMesage] = useState<string>('');
  const extendModal = useTableStore(state => state.extendModal)
  const setExtendModal = useTableStore(state => state.setExtendModal)
  const [tcns, setTcns] = useState<Array<any>>([])
  const [extendTCN, setExtendTCN] = useState<string>("")
  const [selected, setSelected] = useState<any>([])
  const [createSearch, setCreateSearch] = useState(false);
  const [formValues, setFormValues] = useState<any>({})
  const [extendStatus, setExtendStatus] = useState()
  const [closeAlert, setCloseAlert] = useState<boolean>(false);
  const [loadConfig, setLoadConfig] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [data, setData] = useState<any[]>([]);
  //Set true when a simple singular parameter search has run
  const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
  const [value, setValue] = React.useState<any>([]);
  const [appName, setAppName] = useState<string>('CARES');
  const [displaySavedMessage, setDisplaySavedMessage] = useState<boolean>(false);
  const [searchLength, setSearchLength] = useState(0)
  const [rejectName, setRejectName] = useState("")

  const isPost = useTableStore((state) => state.isPost)
  const setIsPost = useTableStore((state) => state.setIsPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const setIsDelete = useTableStore((state) => state.setIsDelete)
  const configFile = useTableStore((state) => state.configFile)
  const setConfigFile = useTableStore((state) => state.setConfigFile)
  const configBody = useTableStore((state) => state.configBody)
  const setConfigBody = useTableStore((state) => state.setConfigBody)
  const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)
  const recordId = useTableStore((state) => state.recordId)
  const setRecordId = useTableStore((state) => state.setRecordId)
  const setSeverity = useTableStore(state => state.setSeverity);
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const setActionsConfig = useTableStore(state => state.setActionsConfig)
  const openNotification = useTableStore((state) => state.openNotification)
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)
  const tableConfig = useTableStore(state => state.tableConfig)


  const { startDate, endDate, currentDate } = GetMaintenanceSchedule(TableNames.TRANSACTION_SUMMARY);
  const { expanded } = useExpanded()
  const { searchData, postedConfig, updatingConfig } = useConfigService(configFile, configBody, true, isPost, isDelete);
  const [formState, dispatchFormValues] = useReducer(searchReducer, { firstName: '' })
  const [clearRecordLimitAlert, setClearRecordLimitAlert] = useLocalStorage("clearRecordLimit")
  const loadedData = useMemo(() => ({ ...searchData }), [searchData]);
  const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);


  const [registrationId, setRegistrationId] = useState("")

  const [rejectTableData, setRejectTableData] = useState([])

  const setApplicantName = useTableStore(state => state.setApplicantName);
  const setTransactionDate = useTableStore(state => state.setTransactionDate);

  const [extendRow, setExtendRow] = useState<any>({})

  const setInternalSearchArray = useTableStore(state => state.setInternalSearchArray)
  const [ transactionViewing, setTransactionViewing ] = useState({})
  const [ filterList, setFilterList ] = useState([])
  const setDefaultOrderBy = useTableStore(state => state.setDefaultOrderBy)
  

  //const [page, setPage] = useState(1)
  //const [rowsPerPage, setRowsPerPage] = useState(25)
  console.log(`10101010 debug network calls ${pageName}, before running query... userConfig: `, userConfig)

  const openApplicantDetails = useCallback(async (e, row: any) => {
    const doesNotTriggerLastViewed = ["mc_admin", "mc_state_police_admin", "mc_state_police_user"]

    const { username } = await Auth.currentAuthenticatedUser()
    let key = row.key
    window.open(`/app/applicant-details/${key}`, '_blank')
    // How audit events are saved - (Action, Details (location of the event), Date(), key = id))
    console.log('open applicant details userRole: ', userGroup)
    addAuditEvent("Viewed", "Details / Adjudication Document", new Date(), key)
    if (userGroup && !doesNotTriggerLastViewed.includes(userGroup!.toString())) {
      upsertViewedRecord(key, username);
      setData((prevData: any) => {
        return prevData.map((data: any) => {
          if (data.key === key) {
            return {
              ...data,
              viewed: true,
              viewedUser: username,
              viewedDate: new Date().toISOString()
            }
          }
          return data
        })
      })
    }
  }, [userGroup])

  const actionsConfig: { actionLabel: string, actionHandler: Function }[] = [
    ...STATE != "me" ? [{
      actionLabel: "Extend",
      actionHandler: (e, row) => {
        e.stopPropagation()
        handleExtendItemSelect({ e, id: row.key, tcn: row.tcn })
        setExtendModal(true)
        setApplicantName(`${row.firstName} ${row.middleName || ""} ${row.lastName}`)
        setTransactionDate(`${row.transactionDate}`)
        setExtendRow(row)

      }
    }] : [],
    {
      actionLabel: "View Applicant Details",
      actionHandler: openApplicantDetails
    }
  ]

  console.log('debug refactor compare actionsConfig: ', actionsConfig)

  const buttonCellHandlers = {
    status: {
      clickHandler: (e, row) => {
        setRejectName(row.firstName + " " + row.lastName)
        let id = row.key
        e.stopPropagation()
        setRegistrationId(id)
        setOpenDrawer(true)
        console.log('debug tableConfig ButtonTableCell Reject Drawer handleOpenRejectDrawer id ', id)
      }
    }
  }

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.TRANSACTION_SUMMARY, { savedSearches, dispatchSearch: () => dispatchSearch }, actionsConfig, buttonCellHandlers)
  useEffect(() => {
    setDefaultOrderBy("transactionDate")
    if(tableConfig[TableNames.TRANSACTION_SUMMARY]?.transactionViewing) {
      setTransactionViewing(tableConfig[TableNames.TRANSACTION_SUMMARY].transactionViewing)
    }

    if(tableConfig[TableNames.TRANSACTION_SUMMARY]?.filterList) {
      setFilterList(tableConfig[TableNames.TRANSACTION_SUMMARY].filterList)
    }
  }, [tableConfig[TableNames.TRANSACTION_SUMMARY]])

  const {
    data: mappedData,
    extendViewing,
    error,
    loading,
    totalRecords,
    runCount
  } = useQueryTransactions(
    rowsPerPage,
    page,
    sortOrder,
    orderBy,
    userRole,
    searchParams,
    true);

  const { upsertViewedRecord } = useQueryRapBack(rowsPerPage, page, sortOrder, orderBy, searchParams, userRole, pageName, pageName)

  useEffect(() => {
    const { sortOrder } = pagination[TableNames.TRANSACTION_SUMMARY]
    console.log('debug reset sort order: ', pagination)
    setSortOrder(sortOrder ? sortOrder : "desc")
  }, [pagination[TableNames.TRANSACTION_SUMMARY]])

  /*useEffect(() => {
    console.log(`debug useSetConfig ${pageName} pagination: `, pagination)
    setSearchParams([])
  }, [])*/

  useEffect(() => {
    console.log('createSearch formState: ', formState)
    const formStateProps = Object.keys(formState)
    if (formStateProps.length === 1 && formStateProps[0] === "days") {
      console.log('createSearch update searchParams')
      setSearchParams([{ column: "days", value: formState.days }])
    }
  }, [Object.keys(formState).length])

  useEffect(() => {
    console.log('TransactionSummary mappedData: ', mappedData)
    setData(
      mappedData?.map((data: any) => {
        return {
          key: data.key,
          ...(STATE != "ga" ? { rfp: data.rfp } : { stateRFPCode: data.stateRFPCode }),
          ...(STATE === "ga" && { stateRFPLiteral: data.stateRFPLiteral }),
          fullName: data.fullName,
          lastName: data.lastName,
          firstName: data.firstName,
          middleName: data.middleName,
          expireDate: data.expireDate,
          extendCount: data.extendCount,
          ueid: data.ueid,
          dob: data.dob,
          ssn: data.ssn,
          gender: data.gender,
          race: data.race,
          status: data.status,
          hitNoHit: data.hitNoHit,
          agency: data.agency,
          secondaryOri: data.secondaryOri,
          transactionType: data.transactionType,
          tcn: data.tcn,
          transactionDate: data.transactionDate,
          viewed: data.viewed,
          viewedDate: data.viewedDate,
          viewedUser: data.viewedUser,
          rbSub: data.rbSub
        };
      })
    );
  }, [mappedData?.length, mappedData])

  useEffect(() => {
    console.log('debug saved search data here', searchData)
    if (searchData.length > 0) {

      dispatchSearch({
        type: SavedSearchActionTypes.LOAD_SEARCHES,
        searchData,
        configService,
        setters: {
          setIsDelete,
          setConfigFile,
          setConfigService,
          setIsPost,
          setInternalSearchArray
        }
      })
      setConfigService({ ...configService, postedConfig: false })
      setConfigFile('sticky-settings.json')
      setInternalSearchArray(searchData)

    }
  }, [searchData.length]);

  useEffect(() => {
    console.log('debug extend extendStatus', extendStatus)
    setTimeout(() => {
      setExtendStatus(undefined);
    }, 10000)
  }, [extendStatus])

  useEffect(() => {
    console.log('disableHeader transaction summary handleExtendItemSelect tcn:  ', extendTCN)
    if (extendTCN.length < 1) {
      //setDisableHeader(true) do this some other way 
    } else if (extendTCN.length > 0) {
      //setDisableHeader(false)
      const newSelected = mappedData.map((row: any) => extendTCN === row.transactionNumber);
      console.log('transaction  summary newSelected: ', newSelected)
      //setIsSelected(newSelected.indexOf)
      setSelected(newSelected);
    }
  }, [extendTCN])

  useEffect(() => {
    console.log(`debug alert displaySavedMessage ${displaySavedMessage}`)
    if (!displaySavedMessage && postedConfig) {
      setConfigService({ ...configService, postedConfig: false })
    }
  }, [displaySavedMessage])

  useEffect(() => {
    if (usersPermissions?.viewAllTransactionsNoLimit) {
      setAdvancedSearch(true)
    }
  }, [usersPermissions?.viewAllTransactionsNoLimit])

  const handleCloseFirstTimeAlert = async () => {
    setClearRecordLimitAlert(true)
    setCloseAlert(true);
    setIsDelete(true);
    setConfigFile('alert-closed.json');
    setConfigBody({ closedAlert: 'true' });
    setIsPost(true);
  }

  useEffect(() => {
    setPagination({ ...pagination, [TableNames.TRANSACTION_SUMMARY]: { page: 1, rowsPerPage: 25 } })
    setTimeout(() => {
      setConfigFile('alert-closed.json');
    }, 2000)
  }, [])

  const closeExtend = () => {
    setExtendModal(false)
  }

  const handleExtendViewing = useCallback(async (date, numDaysExtended: number) => {
    console.log("debug extendView handleExtendViewing... id: ", recordId);
    //setclickLoading(true);
    if (recordId) {
      const res: any = await extendViewing(recordId, date, numDaysExtended, config);
      console.log("debug extendView handleExtend res: ", res);
      if (res.status === 200) {
        addAuditEvent("Extended Record", "Transaction Summary", new Date(), recordId);
        setAlertTitle('Success')
        setAlertMessage(res.msg);
        setSeverity(AlertColor.SUCCESS)
        setOpenNotification(true)
        setData((prevData: any) => {
          return prevData.map((data: any) => {
            if (data.key === recordId) {
              return {
                ...data,
                extendCount: data.extendCount + 1
              }
            }
            return data
          })
        })
        setTimeout(() => {
          setOpenNotification(false)
          setExtendMesage('')
          setExtendStatus(undefined)
          setSeverity('')
        }, 10000)

      } else if (res.status !== 200) {
        setAlertTitle('Error')
        setSeverity('error');
        setAlertMessage(res.msg);
        setOpenNotification(true)
        setSeverity(AlertColor.ERROR)
        setTimeout(() => {
          setOpenNotification(false)
          setExtendMesage('')
          setExtendStatus(undefined)
          setSeverity('')
        }, 10000)
      }
    } else {
      return
    }

    setExtendModal(false)

  }, [recordId, config]);

  const handleExtendItemSelect = (params: any) => {
    console.log('debug extend transaction summary handleExtendItemSelect params:  ', params)
    const { e, id, tcn } = params
    console.log('debug extend transaction summary handleExtendItemSelect tcn:  ', tcn)
    setRecordId(id)
    e.stopPropagation()
    const tcnList = tcns

    if (tcn === extendTCN) {
      console.log(`debug extend transaction summary handleExtendItemSelect  tcn ${tcn} ===  ${extendTCN}  `)
      setExtendTCN("")
    } else {
      console.log(`debug extend transaction summary handleExtendItemSelect  tcn ${tcn} !=  ${extendTCN}  `)
      setExtendTCN(tcn)
    }
    //setTcns(tcnList)
    console.log('debug extend transaction summary handleExtendItemSelect id:  ', id)
  }

  const handleRunSearch = () => {
    setPagination({
      ...pagination,
      [TableNames.TRANSACTION_SUMMARY]: {
        page: 1,
        rowsPerPage: 25,
        sortOrder: "desc",
        orderBy: ""
      }
    })
    setDisplaySavedMessage(false)
    console.log(`createSearch handleRunSearch formState ${JSON.stringify(formState, null, 2)}`)
    const searchParams = Object.keys(formState).map((key: string) => {
      return { column: key, value: formState[key] }
    })
    console.log(`createSearch handleRunSearch searchParams ${JSON.stringify(searchParams, null, 2)}`)
    const searchParamsFiltered = searchParams.filter((obj) => obj.value !== "" && obj.value !== "30" && obj.value !== "90" && obj.value !== "120")
    console.log('createSearch handleRunSearch  searchLength: ', searchParamsFiltered)
    setSearchLength(searchParamsFiltered.length)
    setSearchParams(searchParams)
    setCreateSearch(false)
    setSearched(true)
    setValue([])
  }

  useEffect(() => {
    if (displayNoSearchResults) {
      setTimeout(() => {
        setOpenNotification(false)
      }, 10000)
    }
  }, [displayNoSearchResults])

  useEffect(() => {
    const debugEmptyRows: LogConfig = {
      identifier: "debugEmptyRows",
      pageName: "transaction-summary",
      thingToDebug: "Transaction Summary useEffect to set displayNoSearchResults",
      paramsToLog: {
        simpleSearchRan,
        mappedDataLength: mappedData.length,
        setDisplayNoSearchResults: mappedData.length === 0 && simpleSearchRan || mappedData.length === 0 && searched
      }
    }
    logger(debugEmptyRows)
    console.log('debug stuck search results useEffect: ', { runCount, searchParams, mappedData, simpleSearchRan, searched, setDisplayNoSearchResults: mappedData.length === 0 && simpleSearchRan || mappedData.length === 0 && searched })
    console.log('debug stuck search results useEffect: ', { conditional: runCount < 1 && searchParams.length > 0, negativeConditional: !(runCount < 1 && searchParams.length > 0) })
    if (runCount < 1 && searchParams.length > 0) {
      setDisplayNoSearchResults(false)
      setOpenNotification(false)
      return
    }
    if (mappedData.length === 0 && simpleSearchRan || mappedData.length === 0 && searched) {
      setDisplayNoSearchResults(true)
      setOpenNotification(true)
    }
    console.log('debug open notification display no seasrch', displayNoSearchResults)
    console.log('debug open notification', openNotification)

  }, [mappedData?.length, simpleSearchRan, searched])

  const handleRequestSort = (event: any, property) => {
    if (property === "reasonFingerprinted") {
      property = "rfp"
    } else if (property === "transactionType") {
      property = "tot"
    } else if (property === "transactionNumber") {
      property = "tcn"
    }
    console.log(`debug sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
    setSortOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property);
  };

  const setAdvancedSearch = useTableStore(state => state.setAdvancedSearch)
  useEffect(() => {
    if (usersPermissions?.viewAllTransactionsNoLimit) {
      setAdvancedSearch(true)
    }
  }, [usersPermissions?.viewAllTransactionsNoLimit])

  const handleCancel = () => {
    setOpenDrawer(false)
    console.log(`debug useSetConfig in pagination  in handleCancel tableConfig: `, tableConfig)
    setRejectTableData([])
    setRegistrationId("")

    setPagination({
      ...pagination,
      [TableNames.REJECT_CODES]: {
        page: 0,
        rowsPerPage: 5,
        sortOrder: "desc",
        orderBy: ""
      }
    })
  }

  useEffect(() => {
    console.log('debug handle close alert changed: ', { displayNoSearchResults, searched, searchParams })
  }, [displayNoSearchResults, searched, searchParams])

  return (
    <Paper elevation={0} sx={
      {
        ...pageStyles.pagePaperStyle,
        maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
      }}>
      <CustomTableTitleAndAlert
        setSearched={() => setSearched}
        setSearchParams={() => setSearchParams}
        setDisplayNoSearchResults={() => setDisplayNoSearchResults}
        name="Transaction Summary"
      />
      {(startDate && currentDate < startDate) ?
        <Alert severity='warning' sx={AlertStyles.warning}>
          <AlertTitle color='warning.contrastText'>
            Maintenance Scheduled.
          </AlertTitle>
          <Typography color='warning.contrastText' variant='body2'>
            {`Please be aware that ${appName} will be offline starting at ${startDate} for scheduled maintenance and will be back online after ${endDate}.`}
          </Typography>
        </Alert> : null
      }
      {loadingConfig || alertData?.closedAlert === undefined ?
        null
        : !alertData?.closedAlert && !closeAlert && !clearRecordLimitAlert &&
        <Alert severity='info'
          sx={AlertStyles.info}
          action={
            <Tooltip title="Close">
              <IconButton aria-label="close" size="small" onClick={() => handleCloseFirstTimeAlert()}>
                <CloseIcon sx={{ color: 'info.contrastText' }} />
              </IconButton>
            </Tooltip>
          }
        >
          <AlertTitle sx={{ color: 'info.contrastText' }}>
            30 Days Record Display Limit
          </AlertTitle>
          <Typography color='info.contrastText' variant='body2'>
            Transaction Summary table only displays the last 30 days of applicants.
            {usersPermissions?.viewAllTransactionsNoLimit && ' To adjust the 30 day window, use "Advanced Search" to customize your date range of applicants.'}
          </Typography>
        </Alert>
      }
      <RightDrawer containsTable={true} openDrawer={openDrawer} closeDrawer={handleCancel}>
        <Typography sx={{ marginBottom: "10px" }} variant="h5">{rejectName} Reject Information</Typography>
        <RejectCodeTable
          registrationId={registrationId}
          name="reject-codes" />
      </RightDrawer>
      {
        tableHeaderCells && (
          <CustomTable
            //Required props
            tableConfig={tableConfig}
            tableName="transaction-summary"
            loading={loading}
            rows={data}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            order={sortOrder}
            totalRecords={totalRecords}
            currentPage={page}
            pageSize={rowsPerPage}
            searched={searched}
            value={value}
            handleRequestSort={handleRequestSort}
            setValue={setValue}
            // setSimpleSearchRan={setSimpleSearchRan}
            setSearched={setSearched}
            //Per page props
            headerButtonAriaLabel="extend-record(s)"
            headerButtonText="Extend"
            rowClickHandler={(e, row) => openApplicantDetails(e, row)}
            isSelectedRow={selected}
            tcns={tcns}
            extendTCN={extendTCN}
            setSelected={setSelected}
            //Advanced search props
            advancedSearchNumParams={searchLength}
            setSearchLength={setSearchLength}
            setCreateSearch={setCreateSearch}
            setDisplaySavedMessage={setDisplaySavedMessage}
            savedSearchState={[savedSearches, dispatchSearch]}
            dispatchFormValues={dispatchFormValues}
          />)

      }
      {
        extendModal && (
          <ExtendModal
            open={extendModal}
            handleExtend={handleExtendViewing}
            handleClose={closeExtend}
            data={mappedData}
            row={extendRow}
          />
        )
      }
      {
        createSearch && (
          <AdvancedSearch
            updatingConfig={updatingConfig}
            setSearchLength={setSearchLength}
            filters={filterList}
            transactionViewing={transactionViewing!}
            usersPermissions={usersPermissions}
            setCreateSearch={setCreateSearch}
            handleRunSearch={handleRunSearch}
            //updateForm={updateForm}
            formValues={formValues}
            formState={[formState, dispatchFormValues]}
            setFormValues={setFormValues}
            savedSearchState={[savedSearches, dispatchSearch]}
            postedConfig={postedConfig}
            //setPostedConfig={setPostedConfig}
            displaySavedMessage={displaySavedMessage}
            setDisplaySavedMessage={setDisplaySavedMessage}
          />
        )
      }
    </Paper>
  )
} 